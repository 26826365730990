
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import { SiteZone, ZoneTypes } from "@/store/modules/zone/zone.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const zoneX = namespace(ZoneTypes.MODULE);

@Component({
  components: { DatePicker },
})
export default class EditZone extends Mixins(ValidationMixin) {
  @zoneX.State(ZoneTypes.UPDATE_ZONE_STATE)
  public updateZone!: DefaultState;

  @zoneX.State(ZoneTypes.ZONE_DATA)
  public issueZone!: SiteZone;

  @zoneX.Mutation(ZoneTypes.SET_UPDATE_ZONE_DIALOG)
  public setUpdateZone!: (updateZone: boolean) => void;

  @zoneX.Action(ZoneTypes.UPDATE_ZONE)
  public saveZone!: (farmer: SiteZone) => Promise<void>;

  public updateZoneStep = 1;

  public isValid = false;

  public zone: SiteZone = {
    id: -1,
    name: "",
    description: "",
    precedence: 0,
    active: false,
    createdAt: new Date(),
    priorities: [],
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateZoneDialog(): void {
    this.setUpdateZone(false);
  }

  async saveZoneToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveZone(this.zone);
    }
  }

  @Watch("issueZone")
  onDataChanged(value?: SiteZone): void {
    this.zone = Object.assign({}, value);
  }

  mounted(): void {
    this.zone = Object.assign({}, this.issueZone);
  }
}
